import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
} from '@mui/material';
import NavigationBar from '../../components/NavigationBar/NavigationBar.tsx';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username.trim() === '' || password.trim() === '') {
      setError('Please fill in all fields.');
      return;
    }

    const success = login(username, password);
    if (success) {
      navigate('/'); // Redirect to home page
    } else {
      setError('Invalid username or password.');
    }
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#0f0f1c' }}>
      {/* Add NavigationBar */}
      <NavigationBar />

      {/* Login Form */}
      <Container
        maxWidth="xl"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 64px)', // Subtract Navbar height
          padding: '2rem',
        }}
      >
        <Paper
          elevation={6}
          style={{
            padding: '2rem',
            width: '100%',
            maxWidth: '400px',
            backgroundColor: '#112240',
            borderRadius: '10px',
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            style={{
              color: '#FFFFFF',
              fontWeight: 'bold',
            }}
          >
            Login
          </Typography>
          {error && (
            <Typography
              variant="body1"
              align="center"
              style={{ color: 'red', marginBottom: '1rem' }}
            >
              {error}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
            }}
          >
            <TextField
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
              InputLabelProps={{
                style: { color: '#8892B0' },
              }}
              InputProps={{
                style: { color: '#FFFFFF' },
              }}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              InputLabelProps={{
                style: { color: '#8892B0' },
              }}
              InputProps={{
                style: { color: '#FFFFFF' },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#00C6FF',
                color: '#FFFFFF',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#0072FF',
                },
              }}
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginPage;
