import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Alert, Box } from '@mui/material';

export default function ReusableTable({ rows, columns }) {
  if (!rows || rows.length === 0) {
    return (
      <Alert 
        severity="error" 
        sx={{
          textAlign: 'center', 
          backgroundColor: '#f44336', 
          color: '#fff',
        }}
      >
        No data available.
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        height: '400px',
        width: '100%',
        marginTop: '20px',
        backgroundColor: '#1E293B', // Dark theme
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.4)', // Modern shadow
        padding: '20px',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row?.id}
        sx={{
          backgroundColor: '#1E293B',
          color: '#FFFFFF',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#334155',
            color: '#00C6FF',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid rgba(224, 224, 224, 0.1)',
            color: '#FFFFFF',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(odd)': {
              backgroundColor: '#293548',
            },
            '&:hover': {
              backgroundColor: '#334155',
            },
          },
          '& .MuiCheckbox-root': {
            color: '#00C6FF',
          },
        }}
      />
    </Box>
  );
}
