import React from 'react';
import PageLayout from '../../components/PageLayout/PageLayout.tsx';
import './HomePage.css';

function HomePage() {
  return (
    <PageLayout>
      <section className="hero-section">
        <div className="row">
          <div className="text-container">
            <h1>
              Enterprise AI Solutions for Restaurant <br /> Monitoring <br />
            </h1>
            <h5>Welcome To</h5>
            <h2>NEOM AUTHORITY</h2>
            <button className="learn-more-button">
              Learn more <span className="material-symbols-outlined">arrow_forward</span>
            </button>
          </div>
          <div>
            <img src="/image2-low-res.webp" alt="Mountain" className="Img2" />
            <img src="/image1-low-res.webp" alt="Nature" className="Img1" />
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default HomePage;
