import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const NavigationBar = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout(); // Perform logout
    window.location.href = '/login'; // Redirect to the login page
  };

  return (
    <nav className="bg-gray-800 text-white px-8 py-4 flex items-center justify-between shadow-md">
      {/* Navigation Links shifted to the left */}
      <ul className="flex space-x-6">
        <li>
          <Link to="/" className="hover:text-cyan-400 text-lg">
            Home
          </Link>
        </li>
        <li>
          <Link to="/iot" className="hover:text-cyan-400 text-lg">
          IoTSense
          </Link>
        </li>
        <li>
          <Link to="/reviews" className="hover:text-cyan-400 text-lg">
          ReviewsRadar
          </Link>
        </li>
        <li>
          <Link to="/streams" className="hover:text-cyan-400 text-lg">
          VisionSense
          </Link>
        </li>
      </ul>

      {/* Logout Button */}
      <button
        onClick={handleLogout}
        className="bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded"
        style={{
          backgroundColor: '#F1EFE0', // Updated color for Logout button
          color: '#13100D', // White text
          padding: '10px 20px', // Spacing for better visibility
          borderRadius: '20px',
        }}
      >
        Logout
      </button>
    </nav>
  );
};

export default NavigationBar;
