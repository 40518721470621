import React, { createContext, useState, useContext, useEffect } from 'react';

// Parse credentials from environment variable
const credentials = (() => {
  try {
    const raw = process.env.REACT_APP_AUTH;
    return JSON.parse(raw); // Parse the JSON string
  } catch (error) {
    console.error('Failed to parse REACT_APP_AUTH:', error.message);
    return {}; // Return an empty object if parsing fails
  }
})();

// Create Auth Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize `isAuthenticated` from `localStorage`
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const savedAuthState = localStorage.getItem('isAuthenticated');
    return savedAuthState === 'true'; // Convert string to boolean
  });

  useEffect(() => {
    // Persist authentication state to localStorage whenever it changes
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  const login = (username, password) => {
    if (credentials[username] && credentials[username] === password) {
      setIsAuthenticated(true);
      return true;
    }
    return false;
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Clear authentication state
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use Auth Context
export const useAuth = () => useContext(AuthContext);
