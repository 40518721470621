import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HomePage from './pages/HomePage/HomePage.tsx';
import TemperaturePage from './pages/TemperaturePage/TemperaturePage';
import ReviewsPage from './pages/ReviewsPage/ReviewsPage';
import ImagesPage from './pages/ImagePage/ImagesPage';
import NavigationBar from './components/NavigationBar/NavigationBar.tsx';
import StreamsPage from './pages/StreamsPage/StreamsPage';
import ImageDetail from './pages/ImagePage/ImageDetail';
import LoginPage from './pages/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, useAuth } from './context/AuthContext';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
    primary: {
      main: '#00C6FF',
    },
    secondary: {
      main: '#dc004e',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#8892B0',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      color: '#FFFFFF',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#00C6FF',
    },
    body1: {
      fontSize: '1rem',
      color: '#8892B0',
    },
  },
});

// Authenticated Layout Component
const AuthenticatedLayout = ({ children }) => {
  return (
    <div style={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
      <NavigationBar />
      {children}
    </div>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public Route */}
            <Route path="/login" element={<LoginPage />} />

            {/* Private Routes */}
            <Route
              path="/"
              element={
                <AuthenticatedLayout>
                  <PrivateRoute>
                    <HomePage />
                  </PrivateRoute>
                </AuthenticatedLayout>
              }
            />
            <Route
              path="/iot"
              element={
                <AuthenticatedLayout>
                  <PrivateRoute>
                    <TemperaturePage />
                  </PrivateRoute>
                </AuthenticatedLayout>
              }
            />
            <Route
              path="/reviews"
              element={
                <AuthenticatedLayout>
                  <PrivateRoute>
                    <ReviewsPage />
                  </PrivateRoute>
                </AuthenticatedLayout>
              }
            />
            <Route
              path="/streams"
              element={
                <AuthenticatedLayout>
                  <PrivateRoute>
                    <StreamsPage />
                  </PrivateRoute>
                </AuthenticatedLayout>
              }
            />
            <Route
              path="/images"
              element={
                <AuthenticatedLayout>
                  <PrivateRoute>
                    <ImagesPage />
                  </PrivateRoute>
                </AuthenticatedLayout>
              }
            />
            <Route
              path="/images/:streamId/:frame_path"
              element={
                <AuthenticatedLayout>
                  <PrivateRoute>
                    <ImageDetail />
                  </PrivateRoute>
                </AuthenticatedLayout>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
