import React, { useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import ReusableTable from '../../components/ReusableTable';
import { Alert } from '@mui/material';

const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'No Date Available';
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) return 'No Date Available';
  return date.toISOString().replace('T', ' ').split('.')[0]; // Format as YYYY-MM-DD HH:MM:SS
};

const columns = (onImageClick) => [
  {
    field: 'frame_name',
    headerName: 'Frame Path',
    width: 200,
    renderCell: (params) => (
      <span
        onClick={() => onImageClick(params.row)} // Pass the entire row to handleImageClick
        style={{ color: '#00C6FF', textDecoration: 'none', cursor: 'pointer' }}
      >
        {params.value}
      </span>
    ),
  },
  { field: 'object_data', headerName: 'Detection Info', width: 400 },
  { field: 'stream_name', headerName: 'Stream Name', width: 400 },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    width: 200,
    renderCell: (params) => (
      <span>{formatTimestamp(params.row.timestamp)}</span> // Format timestamp
    ),
  },
];

export default function ImagesTable({ data }) {
  const [selectedImage, setSelectedImage] = useState(null); // Store selected row details
  const [open, setOpen] = useState(false); // Modal state

  const handleImageClick = (row) => {
    setSelectedImage(row); // Store the entire row details (including stream_name and frame_name)
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear selected image details
  };

  if (!data || data.length === 0) {
    return (
      <Alert severity="error" sx={{ textAlign: 'center', backgroundColor: '#f44336', color: '#fff' }}>
        No images data available.
      </Alert>
    );
  }

  // Ensure each row has a unique id
  const rows = data.map((row, index) => ({
    ...row,
    id: row.id || index, // Use `id` if available, otherwise fallback to index
  }));

  return (
    <div>
      <ReusableTable rows={rows} columns={columns(handleImageClick)} />

      {/* Modal to display the selected image */}
      <Modal open={open} onClose={handleClose} aria-labelledby="image-modal-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#1E293B',
            padding: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Typography id="image-modal-title" variant="h6" style={{ color: '#FFFFFF' }}>
            Detected Frame
          </Typography>
          {selectedImage && (
            <img
              src={`http://neom-restaurant-inspection.com/images/${selectedImage.stream_name}/${selectedImage.frame_name}`} // Use stream_name and frame_name dynamically
              alt="Detected Frame"
              style={{ maxWidth: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
