import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Get params from the URL
import { Container, Typography, Box, Button,CircularProgress } from '@mui/material';

export default function ImageDetail() {
  const { stream_name, frame_path } = useParams(); // Get stream_name and frame_path from the URL params
  const decodedFramePath = decodeURIComponent(frame_path);  // Decode the frame_path from the URL
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Log the URL parameters for debugging
  console.log("Current stream_name:", stream_name);
  console.log("Current frame_path:", decodedFramePath);

  useEffect(() => {
    setLoading(true);  // Ensure loading is true while fetching

    if (decodedFramePath && stream_name) {
      console.log("Fetching data for stream_name:", stream_name, "and frame_path:", decodedFramePath);

      const apiUrl = process.env.REACT_APP_API_URL || 'http://178.62.2.230:5000';

      // Fetch image data for the specific stream_name and frame_path
      fetch(`${apiUrl}/images-data?stream_name=${stream_name}&frame_path=${decodedFramePath}`)
        .then((response) => {
          console.log('Fetch response:', response);  // Log the fetch response
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log('Fetched image data:', data);  // Log the fetched data
          const foundImage = data.find(img => img.frame_path === decodedFramePath);
          if (foundImage) {
            setImage(foundImage);
            setError(null);
          } else {
            setError('No image found with the specified path.');
          }
        })
        .catch((error) => {
          console.error('Error fetching image data:', error);
          setError('Error fetching image data. Please try again later.');
        })
        .finally(() => setLoading(false));  // Stop loading after fetch completes
    } else {
      setLoading(false);
      setError('Invalid frame path or stream_name.');
    }
  }, [decodedFramePath, stream_name]);  // Ensure it re-fetches if path or stream_name changes

  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);  // Go back to the previous page
    } else {
      navigate('/images');  // Navigate to images page if history is empty
    }
  };

  // If still loading
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Full viewport height
          backgroundColor: '#0f0f1c', // Match the page background
        }}
      >
        <CircularProgress size={80} /> {/* Adjust the size as needed */}
      </Box>
    );
  }
  // If there was an error
  if (error) {
    return (
      <div style={{ position: 'relative', backgroundColor: '#0A192F', minHeight: '100vh', padding: '2rem' }}>
        <Container>
          <Typography variant="h4" gutterBottom style={{ color: '#FF0000', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif', textAlign: 'center' }}>
            {error}
          </Typography>
          <Box textAlign="center" p={3}>
            <Button variant="contained" onClick={handleBackClick} style={{ backgroundColor: '#00C6FF', color: '#FFFFFF', marginTop: '20px' }}>
              Go Back
            </Button>
          </Box>
        </Container>
      </div>
    );
  }

  // If the image was successfully loaded
  return (
    <div style={{ position: 'relative', backgroundColor: '#0A192F', minHeight: '100vh', padding: '2rem' }}>
      <Container>
        <Typography variant="h4" gutterBottom style={{ color: '#00C6FF', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif', textAlign: 'center' }}>
          Image Details
        </Typography>
        <Box textAlign="center" p={3}>
          <Typography variant="h5" gutterBottom style={{ color: '#FFFFFF', fontFamily: 'Poppins, sans-serif' }}>
            Detected Frame
          </Typography>
          <img
            src={`http://neom-restaurant-inspection.com/images/${stream_name}/${image?.frame_path}`}  // Use stream_name in the directory
            alt="Detected Frame"
            style={{ maxWidth: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}
          />
          <Typography variant="h6" color="textSecondary" paragraph style={{ color: '#8892B0', marginTop: '20px', fontFamily: 'Poppins, sans-serif' }}>
            {image?.object_data}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ color: '#8892B0', fontFamily: 'Poppins, sans-serif' }}>
            Captured at: {new Date(image?.timestamp).toLocaleString()}
          </Typography>
          <Button variant="contained" onClick={handleBackClick} style={{ backgroundColor: '#00C6FF', color: '#FFFFFF', marginTop: '20px' }}>
            Go Back
          </Button>
        </Box>
      </Container>
    </div>
  );
}
