import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Alert,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import PageLayout from '../../components/PageLayout';
import ReusableTable from '../../components/ReusableTable';

const StreamsPage = () => {
  const [streamsData, setStreamsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [newStream, setNewStream] = useState({ name: '', rtspUrl: '', category: '' });

  const navigate = useNavigate();

  const columns = [
    { field: 'id', headerName: 'Stream ID', width: 150 },
    { field: 'name', headerName: 'Stream Name', width: 300 },
    { field: 'category', headerName: 'Category', width: 200 },
  ];

  // Fetch streams data from backend
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/streams`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setStreamsData(data.map((stream, index) => ({ ...stream, id: stream.id || index })));
      } catch (err) {
        console.error('Failed to fetch streams data:', err);
        setError('Failed to fetch streams data.');
      } finally {
        setLoading(false);
      }
    };

    fetchStreams();
  }, []);

  // Open and close dialog
  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  // Handle input and dropdown changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStream((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (event) => {
    setNewStream((prev) => ({ ...prev, category: event.target.value }));
  };

  // Add a new stream
  const handleAddStream = async () => {
    const { name, rtspUrl, category } = newStream;
    if (!name || !rtspUrl || !category) {
      setError('All fields are required.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/streams`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ streamName: name, streamUrl: rtspUrl, category }),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const addedStream = await response.json();
      setStreamsData((prev) => [...prev, { ...addedStream, id: addedStream.id || prev.length }]);
      setOpen(false);
      setNewStream({ name: '', rtspUrl: '', category: '' });
    } catch (err) {
      console.error('Failed to add new stream:', err);
      setError('Failed to add new stream.');
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#0f0f1c',
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <PageLayout title="CCTV Streams">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        {/* Dashboard Button */}
        <Button
          onClick={() => navigate('/images')}
          sx={{
            backgroundColor: '#334155',
            color: '#ffffff',
            padding: '10px 20px',
            borderRadius: '20px',
            fontWeight: 'bold',
            fontSize: '16px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            '&:hover': { backgroundColor: '#1E293B' },
          }}
        >
          Dashboard
        </Button>



        {/* Add Stream Button */}
        <Button
          onClick={handleDialogOpen}
          sx={{
            backgroundColor: '#334155',
            color: '#ffffff',
            padding: '10px 20px',
            borderRadius: '20px',
            fontWeight: 'bold',
            fontSize: '16px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            '&:hover': { backgroundColor: '#1E293B' },
          }}
        >
          Add New Stream
        </Button>
      </Box>

      {error && <Alert severity="error">{error}</Alert>}

      {/* Stream Table */}
      <Box>
        <ReusableTable rows={streamsData} columns={columns} />
      </Box>

      {/* Add Stream Dialog */}
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Add New Stream</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Stream Name"
            fullWidth
            variant="standard"
            value={newStream.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="rtspUrl"
            label="RTSP URL"
            fullWidth
            variant="standard"
            value={newStream.rtspUrl}
            onChange={handleInputChange}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              name="category"
              value={newStream.category}
              onChange={handleCategoryChange}
            >
              <MenuItem value="tables">Uncleaned Tables</MenuItem>
              <MenuItem value="rotton">Rotton Food</MenuItem>
              <MenuItem value="staff">Staff Compliance</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: '#FF0000' }}>
            Cancel
          </Button>
          <Button onClick={handleAddStream} sx={{ color: '#00C6FF' }}>
            Add Stream
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export default StreamsPage;
