
import React from 'react';
import ReusableTable from '../../components/ReusableTable';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'place_id', headerName: 'Place ID', width: 150 },
  { field: 'comment', headerName: 'Comment', width: 300 },
  { field: 'overall_cleanliness', headerName: 'Overall Cleanliness', width: 150 },
  { field: 'restroom_hygiene', headerName: 'Restroom Hygiene', width: 150 },
  { field: 'staff_hygiene', headerName: 'Staff Hygiene', width: 150 },
  { field: 'food_quality', headerName: 'Food Quality', width: 150 },
  { field: 'food_poisoning', headerName: 'Food Poisoning', width: 150 },
];

export default function ReviewsTable({ data }) {
  console.log('ReviewsTable received data:', data);

  return <ReusableTable rows={data || []} columns={columns} />;
}
